@import "config/common.scss";

.DashboardSearchWrapper {
  margin-bottom: 1rem;
  width: 100%;
  position: relative;
  display: flex;
}

.DashboardSearchIcon {
  position: absolute;
  top: 50%;
  left: 1rem;

  width: 1rem;
  height: auto;

  transform: translate(-25%, -50%);
}

.DashboardSearchInput {

  max-width: 100%;
  width: 20rem;
  height: 2rem;

  padding-left: 2rem;

  border: 0.2rem solid $color-secondary;
  border-radius: 1rem;
  outline: none;

  box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);

  transition: box-shadow 0.2s, border 0.2s;
  &:hover {
    box-shadow: 0 10px 20px rgba(12, 116, 255, 0.23), 0 6px 6px rgba(12, 116, 255, 0.23);
    border: 0.2rem solid $color-primary;
  }

}
