@import "config/common.scss";

.Footer {
  height: 4rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-left: 2rem;
  padding-right: 2rem;

  background-color: $color-white;

  border-top: 0.1rem solid $color-secondary;

  border-radius: 1rem 1rem 0rem 0rem;

  color: $text-primary;
}

.FooterSupport {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.75rem;
  color: $text-primary;
}

.FooterRight {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.FooterCopyright {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.5rem;
  color: $text-primary;
}

.FooterTerms {
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.5rem;
  color: $text-primary;

  transition: color 0.2s;

  &:hover {
    color: $color-primary
  }
}

.FooterIcon {
  margin-right: 0.5rem;
}
