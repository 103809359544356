@import "config/common.scss";


.HomesList {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @include for-tablet-portrait-up {
    justify-content: unset;
  }
}

.HomeCardAdd {
  width: 100%;
  height: 6rem;
  max-width: 100%;

  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.5rem;
  background-color: $color-white;
  box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);

  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0 10px 20px rgba(12, 116, 255, 0.23), 0 6px 6px rgba(12, 116, 255, 0.23);
  }

  @include for-tablet-portrait-up {
    width: unset;
    min-width: 12rem;
  }
}

.HomeCard {
  width: 100%;
  min-height: 6rem;
  max-width: 100%;

  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 0.5rem;
  background-color: $color-white;
  box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);

  text-align: left;

  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0 10px 20px rgba(12, 116, 255, 0.23), 0 6px 6px rgba(12, 116, 255, 0.23);
  }

  @include for-tablet-portrait-up {
    width: unset;
    min-width: 12rem;
  }
}

.HomeCardTop {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.HomeCardBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.HomeCardBottomLeft {
  display: flex;
  flex-direction: row;
}

.HomeCardBottomLeftStart {
  margin-right: 0.75rem;
  color: $color-primary;
  font-weight: 500;
  transition: font-weight 0.2s;
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
}

.HomeCardBottomLeftLeads {
  color: $text-primary;
  font-weight: 500;
  transition: font-weight 0.2s;
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
}

.HomeCardBottomRight {

}

.HomeCardIcon {
  margin-left: 0.5rem;
  color: $text-primary;
  transition: color 0.2s;
  cursor: pointer;
  &:hover {
    color: $color-primary;
  }
}

.HomeCardTitle {
  margin-bottom: 0.5rem;
  overflow-wrap: break-word;
  font-size: 1rem;
  font-weight: 700;
}

.HomeCardSubtitle {
  overflow-wrap: break-word;
  font-size: 0.75rem;
  font-weight: 500;
}
