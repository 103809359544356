@import "config/common.scss";

.Header {
  height: 4rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-left: 2rem;
  padding-right: 2rem;

  background-color: white;

  border-bottom: 0.1rem solid $color-secondary;

  color: $text-primary;
}

.HeaderLogo {
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 700;
  font-size: 2rem;
  color: $text-primary;
}

.HeaderLogoImg {
  height: 2rem;
  width: auto;
  margin-right: 0.5rem;

  @include for-tablet-portrait-up {
    height: 2.5rem;
  }
}

.HeaderRightWelcome {
  font-size: 1rem;

  @include for-tablet-portrait-up {
    &::before {
      content: "Welcome, ";
    }
  }
}
