@import "config/common.scss";


.ModalContainer {
  position: fixed;
  top:0;
  left:0;
  height: 100vh;
  width: 100vw;

  background-color: $modal-bg;
};

.Modal {
  padding: 2rem;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  background-color: $color-white;
  border-radius: 2rem;
  z-index: 999;
}
