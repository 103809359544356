@import "config/common.scss";

.OpenHomeWrapper {
  min-height: calc(100vh - 11rem);
  padding: 1rem 2rem 2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OpenHome {
  max-width: calc(100% - 4rem);
  width: 50rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.OpenHomeTitle {
  font-size: 3rem;
  font-weight: 700;
  text-align: left;
  margin-bottom: 1rem;
}

.OpenHomeSubtitleRow {
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.OpenHomeSubtitle {
  font-size: 1.5rem;
  font-weight: 700;
}

.OpenHomeAddress {
  font-size: 1.5rem;
}

.OpenHomeForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.OpenHomeFormInputTitle {
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
  font-weight: 500;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }
}

.OpenHomeFormInput {
  width: 100%;
  height: 2rem;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 0.5rem;
  background-color: $color-secondary;
  font-size: 1.6rem;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }
}

.OpenHomeFormCheckboxInput {
  width: 1rem;
  height: 1rem;
  border: 0.1rem solid $color-primary;
  background-color: $color-secondary;

  transition: background-color 0.2s;
  &:checked {
    background-color: $color-primary;
  }
}

.OpenHomeFormInputFeedback {
  margin-bottom: 1rem;
}

.OpenHomeCheckboxWrapper {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.OpenHomeCheckboxDescription {
  font-size: 1rem;
  font-weight: 300;
}

.OpenHomeFormButton {
  margin: auto;
}

.OpenHomeSuccessIcon {
  height: 12rem;
  width: auto;
  font-size: 4rem;
  color: $color-primary;
}

.OpenHomeSuccessText {
  font-size: 2rem;
  font-weight: 700;
  color: $text-primary;
}
