@import "config/common.scss";

.Dashboard {
    min-height: calc(100vh - 11rem);
    background-color: $color-white;

    padding: 1rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    color: $text-primary;
}

.DashboardTop {
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.DashboardTitle {
  font-size: 1.5rem;
  font-weight: 700;
  color: $text-primary;
}

