@import "config/common.scss";

.Leads {
  min-height: calc(100vh - 11rem);
  background-color: $color-white;

  padding: 1rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  color: $text-primary;
}

.LeadsTop {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.LeadsTitle {
  font-size: 1.5rem;
  font-weight: 700;
  color: $text-primary;
}

.LeadsSubtitle {
  font-size: 1rem;
  font-weight: 700;
  color: $text-primary;
}

.LeadsTable {
  margin-bottom: 2rem;

  width: 100%;
  text-align: left;

  border-collapse: collapse;

  tr {
    border-bottom: 0.2rem solid $color-secondary;
  }

  td, th {
    align-items: center;
    width: 25%;
    padding: 1rem 0rem 1rem 0rem;
  }

}

.LeadsExportOptions {
  display: flex;
  flex-direction: row;
}

.LeadsNoLeadsMessage {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.LeadsNoLeadsLink {
  font-weight: 700;
  color: $color-primary;
}
