@import "config/common.scss";

.PaymentWrapper {
  min-height: calc(100vh - 11rem);
  background-color: $color-white;

  padding: 1rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: $text-primary;
}

.PaymentInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.PaymentTitle {
  margin-bottom: 2rem;
  font-size: 2rem;
}

.PaymentButton {
  max-width: 8rem;
}
