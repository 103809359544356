@import "config/common.scss";

.Settings {
  min-height: calc(100vh - 11rem);
  background-color: $color-white;

  padding: 1rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  color: $text-primary;
}

.SettingsTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.SettingsTitle {
  font-size: 1.5rem;
  font-weight: 700;
  color: $text-primary;
  text-align: center;
}

.SettingsSection {
  width: 100%;
  padding: 2rem 0rem 2rem 0rem;
  border-bottom: 0.2rem solid $color-secondary;
}

.SettingsSectionTitle {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: left;
}

.SettingsSectionList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.SettingsButton {
  margin-right: 1rem;
}
