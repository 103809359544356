@import "config/common.scss";

.ResetPassword {
  height: calc(100vh - 8rem);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ResetPasswordBackgroundImage {
  margin-top: 20%;
  height: auto;
  width: 80%;
  position: absolute;
  border-radius: 2rem;
}

.ResetPasswordFormContainer {
  position: absolute;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: $color-white;
  border-radius: 2rem;
  box-shadow: 0 10px 20px rgba(12, 116, 255, 0.23), 0 6px 6px rgba(12, 116, 255, 0.23);
}

.ResetPasswordFormTitle {
  color: $text-primary;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.ResetPasswordForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.ResetPasswordFormInputTitle {
  color: $text-primary;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }
}

.ResetPasswordFormInput {
  margin-bottom: 1rem;
  width: 25rem;
  max-width: 100%;
  padding-left: 0.5rem;
  height: 2rem;
  border: none;
  border-radius: 0.5rem;
  background-color: $color-secondary;
  font-size: 1.6rem;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }
}

.ResetPasswordFormInputFeedback {
  margin-bottom: 2rem;
  width: 100%;
  height: 1rem;
}

.ResetPasswordFormButton {
  margin: auto;
}

.ResetPasswordFormForgotPassword {
  width: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  font-size: 0.75rem;

  color: $text-primary;
}

