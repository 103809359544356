@import "config/common.scss";

.Landing {
  min-height: calc(100vh - 8rem);
  padding: 2rem;
}

// Top Section

.LandingSectionTop {
  margin-top: 2rem;
  display: flex;

  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;

  @include for-tablet-landscape-up {
    margin-top: 6rem;
    flex-direction: row;
  }
}

.LandingLeft {
  position: relative;

  margin-right: 0rem;
  margin-top: calc(8 * 24px + 2rem);
  margin-bottom: 2rem;

  height: 100%;
  width: 80%;

  display: flex;
  justify-content: center;
  align-items: center;

  @include for-tablet-landscape-up {
    margin-right: 1rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    width: 50%;
  }

}

.LandingLeftImg {
  position: absolute;
  max-width: 100%;
  height: auto;
  border-radius: 2rem;
}

.LandingLeftTextBubble {
  position: absolute;
  margin: auto;
  max-width: 100%;
  width: 20rem;
  height: auto;
  border-radius: 2rem;
  padding: 2rem;
  text-align: left;
  color: $color-white;
  background-color: #5BC236;
  line-height: 24px;
  font-weight: 500;
}

.LandingLeftWatchVideo {
  position: absolute;
  margin: auto;
  display: none;

  @include for-tablet-landscape-up {
    display: block;
  }
}

.LandingRight {
  margin-left: 1rem;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  text-align: left;

  @include for-tablet-portrait-up {
    text-align: center;
  }

  @include for-tablet-landscape-up {
    width: 50%;
    text-align: left;
  }
}

.LandingRightTitle {
  padding-bottom: 2rem;

  font-size: 2rem;
  font-weight: 700;
}

.LandingRightSubtitle {
  padding-bottom: 2rem;

  font-size: 1.5rem;
  font-weight: 300;
}

.LandingRightSubtitle--emphasize {
  color: $color-primary;
}

.LandingRightPrice {
  padding-bottom: 1.5rem;

  font-size: 1.5rem;
  font-weight: 300;
}

.LandingRightCta {

}

// How It works section

.LandingSectionHowItWorks {
  margin-top: 6rem;
  padding-top: 2rem;
  border-top: 0.1rem solid $color-secondary;
}

.LandingHowItWorksBlock {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  //width: 35rem;
  max-width: 100%;
  padding: 2rem;
  background: linear-gradient(125deg, $color-primary, #09489b);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LandingHowItWorksBlockItem {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 25rem;
  max-width: 100%;
  padding: 1rem;
  background-color: white;
  color: $text-primary;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  transition: margin-left 0.5s, margin-right 0.5s;
}

.LandingHowItWorksBlockItem--number {
  font-weight: 700;
  font-size: 1.5rem;
  color: $color-primary;
}

.LandingHowItWorksBlockItem--offsetLeft {
  margin-left: 0rem;
  &:hover {
    margin-left: 0rem;
  }

  @include for-tablet-portrait-up {
    margin-left: 4rem;
  }
}
.LandingHowItWorksBlockItem--offsetRight {
  margin-right: 0rem;
  &:hover {
    margin-right: 0rem;
  }
  @include for-tablet-portrait-up {
    margin-right: 4rem;
  }
}

// Features Section

.LandingSectionFeatures {
  margin-top: 6rem;
  padding-top: 2rem;
  border-top: 0.1rem solid $color-secondary;
}

.LandingFeaturesTitle {
  font-size: 2rem;
  font-weight: 700;
}

.LandingFeaturesList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LandingFeatureItem {
  margin-top: 2rem;
  width: 45rem;
  max-width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  color: $text-primary;
}

.LandingFeatureItemIcon {
  margin-right: 1rem;
  height: 3rem;
  width: 3rem;
  color: $text-primary;
}

.LandingFeatureItemDescription {
  font-size: 1.5rem;
  font-weight: 300;
  text-align: left;
}

.LandingSectionPricing {
  margin-top: 4rem;
  padding-top: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-top: 0.1rem solid $color-secondary;
}

.LandingPricingCard {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;

  padding: 2rem;

  width: 16rem;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  color: $color-white;
  background-color: $color-primary;

  border-radius: 2rem;
}

.LandingPricingCardTitle {
  font-size: 2rem;
  font-weight: 500;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.LandingPricingCardTitlePrice {
  font-size: 3rem;
  font-weight: 700;
}

.LandingPricingCardSubtitle {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 300;
}

.LandingPricingCTATitle {
  margin-top: 2rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: $text-primary;
}

.LandingPricingCTAButton {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}
