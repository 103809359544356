@import "config/common.scss";

.Button {
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 2rem;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;

  transition: background-color 0.2s, color 0.2s;
}

.Button--large {
  height: 2rem;
  width: auto;
  padding: 0.5rem 2rem 0.5rem 2rem;

  font-size: 1.5rem;
}

.Button--small {
  height: 1rem;
  width: auto;
}

.Button--outline {
  background-color: $color-white;
  border: 0.2rem solid $color-primary;
  color: $color-primary;

  &:hover {
    background-color: $color-primary;
    color: $color-white;
  }
}

.Button--large.Button--fill {
  //height: 2rem;
  border: 0.2rem solid $color-primary;

  transition: height 0.2s;
  &:hover {
    height: 2rem;
  }
}

.Button--small.Button--fill {
  //height: 1.2rem;
  border: 0.2rem solid $color-primary;

  transition: height 0.2s;
  &:hover {
    height: 1rem;
  }
}

.Button--fill {
  background-color: $color-primary;
  color: $color-white;
  border: 0.2rem solid $color-primary;

  &:hover {
    background-color: $color-white;
    border: 0.2rem solid $color-primary;
    color: $color-primary;
  }
}
