@import "config/common.scss";

.HelpContainer {
  margin-top: 1rem;

  text-align: left;

  background: linear-gradient(125deg, #0C74FF, #09489b);
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
}

.HelpTitle {
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
}

.HelpItems {
  margin-top: 1rem;
}

.HelpItem {
  margin-top: 1rem;
}
