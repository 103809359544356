$font-size-primary: 2rem;
$font-size-secondary: 2rem;

$color-primary: #0C74FF;
$color-secondary: #F0F9FF;
$color-white: #FFFFFF;

$modal-bg: rgba(19, 37, 55, 0.51);

$text-primary: #132537;

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}
