@import "config/common.scss";

.AddHomeContainer {
  color: $text-primary;
}

.AddHome {

}

.AddHomeFormTitle {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
}

.AddHomeForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.AddHomeFormInputTitle {
  font-weight: 300;
  font-size: 1.6rem;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }
}

.AddHomeFormInput {
  width: 20rem;
  height: 2rem;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 0.5rem;
  background-color: $color-secondary;
  font-size: 1.6rem;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }
}

.AddHomeFormInputFeedback {
  margin-bottom: 1rem;
}

.AddHomeFormButton {
  margin: auto;
}
