@import "config/common.scss";

.Signup {
  height: calc(100vh - 8rem);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.SignupBackgroundImage {
  margin-top: 20%;
  height: auto;
  width: 80%;
  position: absolute;
  border-radius: 2rem;
}

.SignupFormContainer {
  position: absolute;
  max-height: calc(100vh - 8rem);
  overflow: scroll;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: $color-white;
  border-radius: 2rem;
  box-shadow: 0 10px 20px rgba(12, 116, 255, 0.23), 0 6px 6px rgba(12, 116, 255, 0.23);
}

.SignupFormTitle {
  color: $text-primary;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.SignupForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.SignupFormLink {
  color: $color-primary;
}

.SignupFormAgreement {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 25rem;
  margin-bottom: 1rem;
  max-width: 100%;
  padding-left: 0.5rem;
}

.SignupFormInputTitle {
  color: $text-primary;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }
}

.SignupFormInputTitle--Agreement {
  font-size: 0.5rem;
  margin-right: 0.5rem;
}

.SignupFormInput {
  margin-bottom: 1rem;
  width: 25rem;
  max-width: 100%;
  padding-left: 0.5rem;
  height: 2rem;
  border: none;
  border-radius: 0.5rem;
  background-color: $color-secondary;
  font-size: 1.6rem;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }
}

.SignupFormInput--Agreement {
  height: 2rem;
  border: none;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  background-color: $color-secondary;
}

.SignupFormInputFeedback {
  margin-bottom: 2rem;
  width: 100%;
  height: 1rem;
}

.SignupFormButton {
  margin: auto;
}

